import React, { useState } from "react";
import { CssBaseline, Box, Drawer, List, ListItem, ListItemText, AppBar, Toolbar, Typography, IconButton, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Dashboard, Announcement, Settings, AccountCircle, Logout, Add } from "@mui/icons-material";
import NotFoundPage from "../Pages/NotFoundPage";
import { useAuth } from "../Context/authContext";

const drawerWidth = 240;

const AdminLayout = ({ children }) => {
  const navigate = useNavigate();
  const isAdmin = localStorage.getItem("isAdmin");
  const [selectedMenuItem, setSelectedMenuItem] = useState("Dashboard");
  const username = localStorage.getItem("username") || "Kullanıcı";

  const { logout } = useAuth(); // AuthContext'ten login işlevini al

  const handleLogout = () => {
    logout();
    navigate("/"); // Redirect to homepage
  };

  const renderContent = () => {
    switch (selectedMenuItem) {
      case "Dashboard":
        return <Typography variant="h6">Dashboarda Hoşgeldiniz</Typography>;
      case "Duyurular":
        return <Typography variant="h6">Duyurunuzu Güncelleyiniz</Typography>;
      case "Ayarlar":
        return <Typography variant="h6">TODO yapılacak</Typography>;
      default:
        return <Typography variant="h6">Hoşgeldiniz</Typography>;
    }
  };

  return isAdmin === "admin" ? (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Admin Panel
          </Typography>
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
          <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
            {username}
          </Typography>
          <Button color="inherit" onClick={handleLogout} startIcon={<Logout />}>
            Çıkış Yap
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { 
            width: drawerWidth, 
            boxSizing: "border-box", 
            backgroundColor: "#333", // Koyu arka plan rengi
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            <Link to="admin/" style={{ textDecoration: 'none' }}>
              <ListItem button onClick={() => setSelectedMenuItem("Dashboard")}>
                <Dashboard sx={{ color: 'gray' }} />
                <ListItemText primary="Dashboard" sx={{ marginLeft: 1, color: 'white' }} />
              </ListItem>
            </Link>
            <Link to="admin/duyuru" style={{ textDecoration: 'none' }}>
              <ListItem button onClick={() => setSelectedMenuItem("Duyurular")}>
                <Announcement sx={{ color: 'gray' }} />
                <ListItemText primary="Duyurular" sx={{ marginLeft: 1, color: 'white' }} />
              </ListItem>
            </Link>
            <Link to="admin/duyuru/create" style={{ textDecoration: 'none' }}>
              <ListItem button onClick={() => setSelectedMenuItem("Ayarlar")}>
                <Add sx={{ color: 'gray' }} />
                <ListItemText primary="Duyuru Ekle" sx={{ marginLeft: 1, color: 'white' }} />
              </ListItem>
            </Link>
            <Link style={{ textDecoration: 'none' }}>
              <ListItem button onClick={() => setSelectedMenuItem("Ayarlar")}>
                <Settings sx={{ color: 'gray' }} />
                <ListItemText primary="Ayarlar" sx={{ marginLeft: 1, color: 'white' }} />
              </ListItem>
            </Link>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3, marginLeft: `${drawerWidth}px` }}
      >
        <Toolbar />
        {children}
        {renderContent()}
      </Box>
    </Box>
  ) : (
    <NotFoundPage />
  );
};

export default AdminLayout;
