import { Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import NotFoundPage from "./Pages/NotFoundPage";
import SingleAnnouncement from "./Components/Body/SingleAnnouncement";
import Login from "./Components/Auth/Login";
import DashBoard from "./Pages/Admin/DashBoard";
import UpdateAnnouncementPage from "./Pages/Admin/Announcements/UpdateAnnouncementsPage";
import AnnouncementsPage from "./Pages/Admin/Announcements/AnnouncementsPage";
import CreateNewAnnouncement from "./Pages/Admin/Announcements/CreateNewAnnouncement";

function App() {
  return (
    <Routes>
      <Route path="/admin/*">
      <Route index element={<DashBoard/>} />
      <Route path="duyuru" element={<AnnouncementsPage />} />
      <Route path="duyuru/update/:id" element={<UpdateAnnouncementPage />} />
      <Route path="duyuru/create" element={<CreateNewAnnouncement />} />
      </Route>
      <Route>
      <Route path="/" element={<HomePage />} />
      <Route path="/anasayfa" element={<HomePage />} />
      <Route path="/duyuru/:id" element={<SingleAnnouncement />} />
      <Route path="/auth" element={<Login />} />
      <Route path='*' element={<NotFoundPage />}/>
      </Route>
    </Routes>
  );
}

export default App;
