import React from "react";
import { Box, Typography, Button } from "@mui/material";

function Sidebar({ latestAnnouncements }) {
  const handleSubscribe = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollStep = 8;
    const delay = 2;

    const scrollAnimation = () => {
      const currentPosition = window.scrollY;

      if (currentPosition + window.innerHeight < scrollHeight) {
        window.scrollTo(0, currentPosition + scrollStep);
        setTimeout(scrollAnimation, delay);
      }
    };

    scrollAnimation();
  };

  // Başlıkları 50 karakterle sınırlandır ve üç nokta ekle
  const truncateTitle = (title) => {
    const maxLength = 50;
    return title.length > maxLength ? title.slice(0, maxLength) + '...' : title;
  };

  return (
    <>
      <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
        <div className="sidebar">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: 300,
              height: 200,
              mx: "auto",
              mt: 5,
              mb: 5,
              p: 3,
              bgcolor: "white",
              color: "black",
              borderRadius: 2,
              boxShadow: 3,
              textAlign: "center",
            }}
          >
            <Typography variant="body1" gutterBottom>
              Son duyuruların bildirimlerini almak ister misin?
            </Typography>
            <Typography variant="body2" gutterBottom>
              O zaman sitemize abone olun.
            </Typography>
            <Button
              variant="contained"
              onClick={handleSubscribe}
              sx={{
                mt: 2,
                bgcolor: "#2f41a5",
                "&:hover": {
                  bgcolor: "#5e8236", // Hover durumunda biraz daha koyu bir yeşil
                },
              }}
            >
              Abone Ol
            </Button>
          </Box>

          {/* Diğer widget'lar */}
          <div className="widget">
            <Typography variant="h6" gutterBottom>
              En güncel Duyurular
            </Typography>
            <div className="blog-list-widget">
              <div className="list-group">
                {latestAnnouncements?.map((announcement, index) => (
                  <a
                    key={index}
                    href="garden-single.html"
                    className="list-group-item list-group-item-action flex-column align-items-start"
                  >
                    <div className="w-100 justify-content-between">
                      <img
                        src={announcement.image}
                        alt=""
                        className="img-fluid float-left"
                      />
                      <h5 className="mb-1">
                        {truncateTitle(announcement.duyuru_title)}
                      </h5>
                      <small>{announcement.duyuru_date}</small>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="widget">
            <Typography variant="h6" gutterBottom>
              Advertising
            </Typography>
            <div className="banner-spot clearfix">
              <div className="banner-img">
                <img src="upload/banner_04.jpg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="widget">
            <Typography variant="h6" gutterBottom>
              Instagram Feed
            </Typography>
            <div className="instagram-wrapper clearfix">
              <a href="#">
                <img
                  src="upload/garden_sq_01.jpg"
                  alt=""
                  className="img-fluid"
                />
              </a>
              <a href="#">
                <img
                  src="upload/garden_sq_02.jpg"
                  alt=""
                  className="img-fluid"
                />
              </a>
              <a href="#">
                <img
                  src="upload/garden_sq_03.jpg"
                  alt=""
                  className="img-fluid"
                />
              </a>
              <a href="#">
                <img
                  src="upload/garden_sq_04.jpg"
                  alt=""
                  className="img-fluid"
                />
              </a>
              <a href="#">
                <img
                  src="upload/garden_sq_05.jpg"
                  alt=""
                  className="img-fluid"
                />
              </a>
              <a href="#">
                <img
                  src="upload/garden_sq_06.jpg"
                  alt=""
                  className="img-fluid"
                />
              </a>
              <a href="#">
                <img
                  src="upload/garden_sq_07.jpg"
                  alt=""
                  className="img-fluid"
                />
              </a>
              <a href="#">
                <img
                  src="upload/garden_sq_08.jpg"
                  alt=""
                  className="img-fluid"
                />
              </a>
              <a href="#">
                <img
                  src="upload/garden_sq_09.jpg"
                  alt=""
                  className="img-fluid"
                />
              </a>
            </div>
          </div>

          <div className="widget">
            <Typography variant="h6" gutterBottom>
              Popular Categories
            </Typography>
            <div className="link-widget">
              <ul>
                <li>
                  <a href="#">
                    Gardening <span>(21)</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    Outdoor Living <span>(15)</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    Indoor Living <span>(31)</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    Shopping Guides <span>(22)</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    Pool Design <span>(66)</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
