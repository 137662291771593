import AnnouncementCard from "./AnnouncementCard";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";

function MainDuyuru() {
  const [records, setRecords] = useState([]);
  const [latestAnnouncements, setLatestAnnouncements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 7;

  useEffect(() => {
    const fetchAllRecords = async () => {
        try {
            const res = await axios.get("https://duyurular.org:8444/api/duyuru/first");
            
            // Aktif olmayan (pasif) ve null/boş olan kayıtları filtrele
            const filteredRecords = res.data.filter(record => record.active !== 'pasif');

            const sortedRecords = filteredRecords.sort((a, b) => {
                const dateA = new Date(a.duyuru_date.split(".").reverse().join("-"));
                const dateB = new Date(b.duyuru_date.split(".").reverse().join("-"));
                return dateB - dateA;
            });

            setRecords(sortedRecords);

            // En güncel 3 duyuruyu ayır
            setLatestAnnouncements(sortedRecords.slice(0, 3));

        } catch (error) {
            console.log(error); // Hata burada loglanıyor
            console.log('çekmedi');
        }
    };

    fetchAllRecords();
}, []);
 // Bağımlılık dizisi boş, bu sayede yalnızca bileşen ilk yüklendiğinde veriyi çeker

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = records.slice(indexOfFirstRecord, indexOfLastRecord);

  const slowScrollToTop = () => {
    const scrollHeight = window.scrollY;
    const scrollStep = scrollHeight / 100;
    const delay = 7;

    const scrollAnimation = () => {
      if (window.scrollY > 0) {
        window.scrollTo(0, window.scrollY - scrollStep);
        setTimeout(scrollAnimation, delay);
      }
    };

    scrollAnimation();
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    slowScrollToTop();
  };

  return (
    <>
      <section className="section wb">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
              <div className="page-wrapper">
                {currentRecords.map((record, index) => (
                  <div key={index} className="blog-list clearfix">
                    <AnnouncementCard record={record} />
                  </div>
                ))}
              </div>
              <hr className="invis" />
              <div className="row">
                <div className="col-md-12">
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-start">
                      <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                        <Link className="page-link" onClick={() => handlePageChange(1)} to="#">
                          1
                        </Link>
                      </li>
                      <li className={`page-item ${currentPage === 2 ? "disabled" : ""}`}>
                        <Link className="page-link" onClick={() => handlePageChange(2)} to="#">
                          2
                        </Link>
                      </li>
                      <li className={`page-item ${currentPage === 3 ? "disabled" : ""}`}>
                        <Link className="page-link" onClick={() => handlePageChange(3)} to="#">
                          3
                        </Link>
                      </li>
                      {currentPage > 3 && (
                        <>
                          <li className="page-item">
                            <span className="page-link">...</span>
                          </li>
                          <li className={`page-item ${currentPage > 3 ? "active" : ""}`}>
                            <Link className="page-link" onClick={() => handlePageChange(currentPage)} to="#">
                              {currentPage}
                            </Link>
                          </li>
                        </>
                      )}
                      <li className={`page-item ${indexOfLastRecord >= records.length ? "disabled" : ""}`}>
                        <Link className="page-link" onClick={() => handlePageChange(currentPage + 1)} to="#">
                          Next
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <Sidebar latestAnnouncements={latestAnnouncements} />
          </div>
        </div>
      </section>
    </>
  );
}

export default MainDuyuru;
