import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Pagination, Box, TextField, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Link } from 'react-router-dom';

function AnnouncementsPage() {
  const [records, setRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(""); // Arama terimi için state
  const [openDialog, setOpenDialog] = useState(false); // Pop-up için state
  const [selectedRecordId, setSelectedRecordId] = useState(null); // Seçilen kayıt ID'si
  const recordsPerPage = 7;

  useEffect(() => {
    const fetchAllRecords = async () => {
      try {
        const res = await axios.get("https://www.duyurular.org:8444/api/duyuru/first"); // Tüm duyuruları getir
        const sortedRecords = res.data.sort((a, b) => {
          const dateA = new Date(a.duyuru_date.split(".").reverse().join("-"));
          const dateB = new Date(b.duyuru_date.split(".").reverse().join("-"));
          return dateB - dateA; // En yeni tarihe göre sıralama
        });

        setRecords(sortedRecords);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllRecords();
  }, []);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = records.slice(indexOfFirstRecord, indexOfLastRecord);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Arama işlemi için fonksiyon
  const handleSearch = async () => {
    try {
      if (searchTerm !== "") {
        const res = await axios.get(`https://www.duyurular.org:8444/api/duyuru/search/${searchTerm}`); // Arama sorgusu
        setRecords(res.data);
      } else {
        const res = await axios.get("https://www.duyurular.org:8444/api/duyuru/first"); // Tüm duyuruları getir
        const sortedRecords = res.data.sort((a, b) => {
          const dateA = new Date(a.duyuru_date.split(".").reverse().join("-"));
          const dateB = new Date(b.duyuru_date.split(".").reverse().join("-"));
          return dateB - dateA; 
        });
        setRecords(sortedRecords);
      }
    } catch (error) {
      console.log('Arama hatası:', error);
    }
  };

  // Silme işlemi için pop-up açma fonksiyonu
  const handleOpenDialog = (id) => {
    setSelectedRecordId(id); // Seçilen kayıt ID'sini ayarla
    setOpenDialog(true); // Pop-up'ı aç
  };

  // Silme işlemi için fonksiyon
  const handleDelete = async () => {
    try {
      await axios.delete(`https://www.duyurular.org:8444/api/duyuru/delete/${selectedRecordId}`); // Silme isteği
      setRecords(records.filter((record) => record.id !== selectedRecordId)); // UI'dan kaldırma
      alert('Duyuru başarıyla silindi!');
    } catch (error) {
      console.log('Silme hatası:', error);
      alert('Silme işlemi sırasında bir hata oluştu.');
    } finally {
      setOpenDialog(false); // Pop-up'ı kapat
      setSelectedRecordId(null); // Seçilen ID'yi sıfırla
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 3 }}>
        <TextField 
          label="Duyuru Başlığı Ara" 
          variant="outlined" 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Arama terimini state'e kaydet
        />
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Ara
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Duyuru Başlığı</TableCell>
              <TableCell>Duyuru Tarihi</TableCell>
              <TableCell>Aktiflik Durumu</TableCell>
              <TableCell align="right">İşlem</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRecords.map((record) => (
              <TableRow key={record.id}>
                <TableCell>{record.id}</TableCell>
                <TableCell sx={{ 
                    maxWidth: 200, 
                    overflow: 'hidden', 
                    textOverflow: 'ellipsis', 
                    whiteSpace: 'nowrap' 
                  }}>
                  {record.duyuru_title}
                </TableCell>
                <TableCell>{record.duyuru_date}</TableCell>
                <TableCell>
                  <Typography 
                    variant="body2" 
                    style={{ color: record.active === 'aktif' || record.active === null || record.active === '' ? 'green' : 'red' }}
                  >
                    {record.active === 'aktif' || record.active === null || record.active === '' ? 'Aktif' : 'Pasif'}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Link to={`/admin/duyuru/update/${record.id}`} state={{ id: record.id }}>
                    <Button variant="contained" color="primary" style={{ marginRight: 10 }}>
                      Güncelle
                    </Button>
                  </Link>
                  <Button 
                    variant="contained" 
                    color="error"
                    onClick={() => handleOpenDialog(record.id)} // Silme işlemi için pop-up'ı aç
                  >
                    Sil
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
        <Pagination
          count={Math.ceil(records.length / recordsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>

      {/* Silme onayı için pop-up */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Silme Onayı</DialogTitle>
        <DialogContent>
          <Typography>
            Bu duyuruyu silmek istediğinizden emin misiniz? Sistemimiz otomatik olarak siteleri taradığından, var olan bu duyuruyu silerseniz tekrar sistemde oluşacaktır. Eğer duyuru asıl sitesinden silinmişse duyuruyu siliniz, eğer değilse güncelleme sayfasından duyuruyu pasife alınız.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">İptal</Button>
          <Button onClick={handleDelete} color="error">Sil</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AnnouncementsPage;
