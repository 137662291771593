import React from "react";
import { Link } from "react-router-dom";

function AnnouncementCard({ record }) {
  const slug = record.duyuru_link.split('/').filter(Boolean).pop();

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  return (
    <>
      <hr className="invis" />
      <div className="blog-box row">
        <div className="col-md-4">
          <div className="post-media">
            <a href="garden-single.html" title="">
              <img style={{ width: "255px", height: "255px" }} src={record.image} alt="" className="img-fluid" />
              <div className="hovereffect" />
            </a>
          </div>
          {/* end media */}
        </div>
        {/* end col */}
        <div className="blog-meta big-meta col-md-8">
          <h4>
            {/* Linke tıklandığında slug gösterilecek ve ID state olarak gönderilecek */}
            <Link to={record.duyuru_content != "" ? `/duyuru/${slug}` : record.duyuru_link} state={{ id: record.id }} title="">
              {truncateText(record.duyuru_title, 150)}
            </Link>
          </h4>
          <p>
            {record.duyuru_unit}
            <br></br>
            {record.duyuru_link}
          </p>
          <small>
            <a href="garden-category.html" title="">
              <i className="fa fa-eye" /> 4442
            </a>
          </small>
          <small>
            <a href="garden-single.html" title="">
              {record.duyuru_date}
            </a>
          </small>
          <small>
            <a href="garden-single.html" title="">
              {record.name}
            </a>
          </small>
        </div>
        {/* end meta */}
      </div>
    </>
  );
}

export default AnnouncementCard;
