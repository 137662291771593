import React from "react";
import SubscriptionCard from "../Components/Body/SubscriptionCard";
import DuyuruData from "../Components/Body/DuyuruData";
import MainDuyuru from "../Components/Body/MainDuyuru";

function HomePage() {
  return (
    <div>
      {/* <SubscriptionCard/> */}
      <MainDuyuru/>
      {/* <DuyuruData /> */}
    </div>
  );
}

export default HomePage;
