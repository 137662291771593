import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';

function SingleAnnouncement() {
  const location = useLocation();
  const { id } = location.state || {}; // Arkadan ID aldım, şimdi API'den çekiyorum

  const [records, setRecords] = useState([]);

  useEffect(() => {
    const fetchAllRecords = async () => {
      try {
        const res = await axios.get(`https://www.duyurular.org:8800/api/duyuru/detay/${id}`); // Sadece ID
        setRecords(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllRecords();
  }, [id]);

  return (
    <section className="section wb">
      <div className="container">
        <div className="row">
          {/* İçerik alanı */}
          <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
            <div className="page-wrapper">
              <div className="blog-title-area">
                <span className="color-green">
                  <a href="garden-category.html" title="">
                    {records.name}
                  </a>
                </span>
                <h3>{records.duyuru_title}</h3>
                <div className="blog-meta big-meta">
                  <small>
                    <a href="garden-single.html" title="">
                      {new Date(records.duyuru_date).toLocaleDateString()}
                    </a>
                  </small>
                  <small>
                    <a href="blog-author.html" title="">
                      by Author
                    </a>
                  </small>
                  <small>
                    <a href title="">
                      <i className="fa fa-eye" /> 2344
                    </a>
                  </small>
                </div>
                <div className="post-sharing">
                  <ul className="list-inline">
                    <li>
                      <a href className="fb-button btn btn-primary">
                        <i className="fa fa-facebook" />{' '}
                        <span className="down-mobile">Share on Facebook</span>
                      </a>
                    </li>
                    <li>
                      <a href className="tw-button btn btn-primary">
                        <i className="fa fa-twitter" />{' '}
                        <span className="down-mobile">Tweet on Twitter</span>
                      </a>
                    </li>
                    <li>
                      <a href className="gp-button btn btn-primary">
                        <i className="fa fa-google-plus" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="single-post-media">
                <img src={records.image} alt="" className="img-fluid" style={{ width: '700px', height: '450px' }} />
              </div>
              <div className="blog-content">
                <div className="pp">
                  <h3>
                    <strong>{records.duyuru_unit}</strong>
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: records.duyuru_content }} // HTML içeriği gösterme
                  />
                  <Link to={records.duyuru_link}>
                    <span>Duyurunun asil sayfasına gitmek için tıklayınız</span>
                  </Link>
                </div>
                <img src="upload/garden_single_02.jpg" alt="" className="img-fluid img-fullwidth" />
                <div className="pp"></div>
              </div>
              <div className="blog-title-area">
                <div className="tag-cloud-single">
                  <span>Tags</span>
                  <small>lifestyle</small>
                  <small>colorful</small>
                  <small>trending</small>
                  <small>another tag</small>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="banner-spot clearfix">
                    <div className="banner-img">
                      <img src="upload/banner_01.jpg" alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
              <hr className="invis1" />
              <div className="custombox clearfix">
                <h4 className="small-title">You may also like</h4>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="blog-box">
                      <div className="post-media">
                        <a href="garden-single.html" title="">
                          <img src="upload/garden_single_03.jpg" alt="" className="img-fluid" />
                          <div className="hovereffect">
                            <span className="" />
                          </div>
                        </a>
                      </div>
                      <div className="blog-meta">
                        <h4>
                          <a href="garden-single.html" title="">
                            We are guests of ABC Design Studio
                          </a>
                        </h4>
                        <small>
                          <a href="blog-category-01.html" title="">
                            Trends
                          </a>
                        </small>
                        <small>
                          <a href="blog-category-01.html" title="">
                            21 July, 2017
                          </a>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="blog-box">
                      <div className="post-media">
                        <a href="garden-single.html" title="">
                          <img src="upload/garden_single_02.jpg" alt="" className="img-fluid" />
                          <div className="hovereffect">
                            <span className="" />
                          </div>
                        </a>
                      </div>
                      <div className="blog-meta">
                        <h4>
                          <a href="garden-single.html" title="">
                            Nostalgia at work with family
                          </a>
                        </h4>
                        <small>
                          <a href="blog-category-01.html" title="">
                            News
                          </a>
                        </small>
                        <small>
                          <a href="blog-category-01.html" title="">
                            20 July, 2017
                          </a>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Sidebar */}
          <Sidebar />
        </div>
      </div>
    </section>
  );
}

export default SingleAnnouncement;
