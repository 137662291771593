import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Link } from "react-router-dom";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isCollapsing, setIsCollapsing] = useState(false);

  const handleToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleSearchBar = () => {
    if (isSearchOpen) {
      setIsCollapsing(true);
      setTimeout(() => {
        setIsCollapsing(false);
        setIsSearchOpen(false);
      }, 300); // CSS transition süresiyle aynı olmalı
    } else {
      setIsCollapsing(true);
      setTimeout(() => {
        setIsCollapsing(false);
        setIsSearchOpen(true);
      }, 300); // CSS transition süresiyle aynı olmalı
    }
  };

  return (
    <>
      <div
        className={`top-search ${isCollapsing ? "collapsing" : ""} ${
          isSearchOpen ? "collapse show" : "collapse"
        }`}
        id="collapseExample"
        style={{
          maxHeight: isSearchOpen ? "150px" : "0",
          overflow: "hidden",
          transition: "max-height 0.5s ease-out !important",
        }}
      >
        <div className="card card-block">
          <div className="newsletter-widget text-center">
            <form className="form-inline">
              <input
                type="text"
                className="form-control"
                placeholder="Aramak istediğiniz kelimeyi giriniz"
              />
              <button type="submit" className="btn btn-primary">
                <SearchIcon />
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="topbar-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 hidden-xs-down">
              <div className="topsocial">
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Facebook"
                >
                  <i className="fa fa-facebook" />
                </a>
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Youtube"
                >
                  <i className="fa fa-youtube" />
                </a>
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Pinterest"
                >
                  <i className="fa fa-pinterest" />
                </a>
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Flickr"
                >
                  <i className="fa fa-flickr" />
                </a>
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Instagram"
                >
                  <i className="fa fa-instagram" />
                </a>
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Google+"
                >
                  <i className="fa fa-google-plus" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 hidden-md-down"></div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div className="topsearch text-right">
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "5px", // Butonlar arasındaki boşluğu ayarlamak için
                  }}
                >
                  <Link to="/auth">
                  <button
                    className="btn btn-outline-primary"
                    style={{ padding: "0.4rem 1rem !important" }}
                  >
                    <LoginIcon
                      style={{ marginRight: "5px", verticalAlign: "middle" }}
                    />
                    Giriş Yap
                  </button>
                  </Link>
                  <Link>
                  <button className="btn btn-outline-primary">
                    <PersonAddIcon
                      style={{ marginRight: "5px", verticalAlign: "middle" }}
                    />
                    Kayıt Ol
                  </button>
                  </Link>

                </div>
                <a
                  data-toggle="collapse"
                  onClick={handleSearchBar}
                  href="#collapseExample"
                  aria-expanded={isSearchOpen}
                  aria-controls="collapseExample"
                  className="collapsed"
                  style={{ marginLeft: "10px" }} // Butonlar ile arama simgesi arasında boşluk oluşturmak için
                >
                  <span>
                    <SearchIcon style={{ verticalAlign: "middle" }} />
                    Ara {/* Arama butonu */}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="logo text-center">
                <a href="garden-index.html">
                  <img
                    src="https://www.duyurular.org/assets/img/logo.png"
                    alt="Logo"
                    style={{ maxWidth: "150px", height: "auto" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <header className="header">
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-inverse">
            <button
              className="navbar-toggler"
              type="button"
              onClick={handleToggle}
              aria-controls="ForestTimemenu"
              aria-expanded={isNavOpen}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
              id="ForestTimemenu"
            >
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <Link className="nav-link color-green-hover" to="/anasayfa">
                    Anasayfa
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link color-green-hover" to="/saglik">
                    Sağlık
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link color-green-hover" to="/resmi-gazete">
                    Resmi Gazete
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link color-green-hover" to="/bakanlik">
                    Bakanlık
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link color-green-hover" to="/kurumlar">
                    Kurumlar
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link color-green-hover" to="/iletisim">
                    İletişim
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>

      <style>
        {`
          @media (min-width: 768px) {
            .navbar-toggler {
              display: none; 
            }
            .navbar-collapse {
              display: flex !important; 
              flex-direction: row; 
            }
            .navbar-nav {
              flex-direction: row;
              margin: 0 auto; /* Ortalamak için */
            }
            .nav-item {
              margin-left: 1rem; 
            }
          }

          @media (max-width: 767px) {
            .navbar-nav {
              flex-direction: column;
              width: 100%;
              margin: 0; /* Ortalamak için */
            }
            .nav-item {
              margin-left: 0;
              margin-bottom: 1rem;
              text-align: center; /* Mobil görünümde yazıları ortalamak için */
            }
          }
        `}
      </style>
    </>
  );
};

export default Header;
